import React, { ReactElement } from 'react';
import dynamic from 'next/dynamic';
import Error404Component from '@/components/404';
import Layout from '@/layout/Layout';

// const Layout = dynamic(() => import('@/layout/Layout'));
const HocHead = dynamic(() => import('@/components/HOC/HocHead'));
// const Error404Component = dynamic(() => import('@/components/404'), {
//   ssr: false,
// });

const ErrorPage = () => {
  const hocHeadData = {
    meta: {
      title: "We couldn't find any matches!",
    },
  };

  return (
    <>
      <HocHead seo={hocHeadData} />
      <Error404Component />
    </>
  );
};

ErrorPage.getLayout = function getLayout(page: ReactElement) {
  return <Layout>{page}</Layout>;
};

export default ErrorPage;
